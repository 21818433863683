import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import Layout from '../../components/layout'
import * as layout from '../../styles/layout.css'
import * as global from '../../styles/global.css'
import { SEO } from '../../components/seo'



const components = {
  p: props => <p className={global.p} {...props} />,
  a: props => <a className={global.a} {...props} />,
  strong: props => <strong className={global.strong} {...props} />,
  code: props => <code className={global.code} {...props} />,
  blockquote: props => <blockquote className={global.blockquote} {...props} />,
  img: props => <img className={global.img} {...props} />,
  h1: props => <h1 className={global.h1} {...props} />,
  h2: props => <h2 className={global.h2} {...props} />,
  h3: props => <h3 className={global.h3} {...props} />,
  h4: props => <h4 className={global.h4} {...props} />,
  h5: props => <h5 className={global.h5} {...props} />,
  ul: props => <ul className={global.ul} {...props} />,
  ol: props => <ol className={global.ol} {...props} />,
  li: props => <li className={global.li} {...props} />,
}


const BlogPost = ({ data}) => {
  console.log(data.mdx.body);
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
        <article className={layout.article}>
        <h2 className={global.h2}>{data.mdx.frontmatter.title}</h2>
        <p className={global.date}>{data.mdx.frontmatter.date}</p>
        <MDXProvider components={components}>      
          <MDXRenderer>
            {data.mdx.body}
          </MDXRenderer>
        </MDXProvider>
        </article>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
      }
      body
      excerpt
    }
  }
`

// console.log(BlogPost().props);
export default BlogPost